import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ForecastPreview from "./children/ForecastPreview";
import ForecastUploadPreview from "./children/ForecastUploadPreview";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";
import { Button } from "@mui/material";

const ForecastMain = () => {
  const [forecast, setForecast] = useState([]);
  const [forecastGeneral, setForecastGeneral] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  let { id } = useParams();
  const { getToken } = useAuthMethod();

  const handleForecast = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    try {
      const resultGeneral = await request(
        process.env.REACT_APP_API_URL + `/forecastgeneraloperation/${id}`,
        "get",
        token
      );
      const resultForecast = await request(
        process.env.REACT_APP_API_URL + `/forecast/${id}`,
        "get",
        token
      );

      const resultFile = await request(
        process.env.REACT_APP_API_URL + `/forecast/upload/url/${id}`,
        "get",
        token
      );

      setForecast(resultForecast);
      setForecastGeneral(resultGeneral);

      setFile(resultFile);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleForecast();
  }, []);
  return (
    <>
      {file ? (
        <ForecastUploadPreview loading={loading} file={file} />
      ) : (
        <ForecastPreview
          loading={loading}
          forecastGeneral={forecastGeneral}
          forecast={forecast}
        />
      )}
    </>
  );
};

export default ForecastMain;
