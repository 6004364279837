import React, { Fragment, useRef, useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { Fonts } from "shared/constants/AppEnums";
import { grey } from "@mui/material/colors/index";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import FormikPatternFormat from "elements/inputs/FormikPatternFormat";
import { checkCnpj } from "shared/utils/cnpj";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";
import FormControl from "@mui/material/FormControl";
import FormikSelect from "elements/inputs/FormikSelect";
import { useAuthUser } from "@crema/utility/AuthHooks";

const validationSchema = yup.object({
  cnpj: yup
    .string()
    .matches(/^\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}$/, {
      message: <em>CNPJ inválido</em>,
    })
    //  .matches(/^[0-9]+$/)
    .min(14)
    .max(19)
    .test("cnpj", "CNPJ inválido", function (value) {
      return checkCnpj(value);
    }),
  clientType: yup
    .mixed()
    .oneOf(["PSP", "VAR"])
    .required("Tipo nao selecionado"),

  email: yup.string().email().required("email invalido"),
  name: yup.string().required(""),
  salesName: yup.string().required(""),
});

const NewProposal = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();

  const { user } = useAuthUser();
  const allowedUsers = [
    "fernando.lucas@entrepay.com.br",
    "leandro.ferraro@entrepay.com.br",
    "bruno.bezouro@entrepay.com.br",
    "mariana.melo@entrepay.com.br",
    "kaique.figueiredo@dopster.io",
    "nicolas.pamplona@entrepay.com.br",
    "gustavo.rodrigues@entrepay.com.br",
    "lucas.ferreira@entrepay.com.br",
    "luiz.coelho@entrepay.com.br",
    "rubens.prada@entrepay.com.br",
    "fernando.amalfi@entrepay.com.br",
    "felipe.pelicer@entrepay.com.br",
    "jennifer.oliveira@aryainv.com",
  ];
  const userIsAllowed = allowedUsers.includes(user.email);

  const handleClose = () => {
    setOpen(false);
  };

  if (!userIsAllowed) {
    return (
      <Fragment>
        <Box
          sx={{
            pb: 6,
            py: { xl: 8 },
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              maxWidth: 576,
              width: "100%",
              textAlign: "center",
              padding: { xs: 8, lg: 12, xl: "48px 64px" },
              overflow: "hidden",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Box
              sx={{
                mb: { xs: 3, xl: 4 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AppBar
                position="static"
                sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
              >
                <Toolbar>
                  <Typography
                    sx={{
                      mb: 1.5,
                      fontWeight: Fonts.BOLD,
                      fontSize: 20,
                      flexGrow: 1,
                    }}
                  >
                    Cadastrar proposta
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <Box
              sx={{
                mt: { xs: 3, xl: 4 },
                textAlign: "center",
                color: grey[700],
                fontSize: 14,
                fontWeight: Fonts.BOLD,
              }}
            >
              <Typography
                sx={{
                  textAlign: "justify",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                Esta pagina está desabilitada!
              </Typography>
            </Box>
          </Card>
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Cadastrar Proposta
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>

          <Formik
            validateOnChange={true}
            initialValues={{
              name: "",
              cnpj: "",
              clientType: "",
              email: "",
              salesName: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (data) => {
              const token = await getToken();

              try {
                const response = await request(
                  process.env.REACT_APP_API_URL + `/lead`,
                  "post",
                  token,
                  {
                    ...data,
                    cnpj: data.cnpj.replace(/[^\d]+/g, ""),
                    client_type: data?.clientType,
                    sales_name: data?.salesName,
                  }
                );
                setOpen(true);
                // Decidir para que pagina ir
                // return navigate(`/onboarding/proposals/standard/${response.data.id}`);
              } catch (error) {
                if (error.response.status === 409) {
                  toast.error("CNPJ já cadastrado no sistema");
                } else {
                  toast.error("Erro ao cadastrar CNPJ");
                  console.error(error);
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form
                sx={{
                  textAlign: "left",
                }}
                noValidate
                autoComplete="off"
              >
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Nome fantasia:
                  </Typography>

                  <AppTextField
                    name="name"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    CNPJ:
                  </Typography>

                  <FormikPatternFormat
                    required
                    format="##.###.###/####-##"
                    allowEmptyFormatting
                    mask="_"
                    name="cnpj"
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Email:
                  </Typography>
                  <AppTextField
                    name="email"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Tipo de Cliente
                  </Typography>
                  <FormControl fullWidth>
                    <FormikSelect
                      name="clientType"
                      id="clientType"
                      placeholder="Tipo do adquirente"
                      options={[
                        { value: "PSP", label: "PSP" },
                        { value: "VAR", label: "VAR" },
                      ]}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Vendedor:
                  </Typography>
                  <AppTextField
                    name="salesName"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    width: "100%",
                    backgroundColor: "#000536",
                    height: 44,
                  }}
                  type="submit"
                >
                  Enviar {isSubmitting && <CircularProgress />}
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Email enviado para o cliente com os proximos passos.
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </Form>
            )}
          </Formik>

          <Box
            sx={{
              mt: { xs: 3, xl: 4 },
              textAlign: "center",
              color: grey[700],
              fontSize: 14,
              fontWeight: Fonts.BOLD,
            }}
          ></Box>
        </Card>
      </Box>
    </Fragment>
  );
};

export default NewProposal;
