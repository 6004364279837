import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography, useScrollTrigger } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import DocumentsUpload from "./DocumentsUpload";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

const ActionButton = styled(Button)({
  marginRight: 5,
  marginTop: 2,
  padding: "9px 12px",
  lineHeight: 1,
  width: 96,
  fontWeight: Fonts.MEDIUM,
  backgroundColor: "#000536",
});

const Compliance = () => {
  const [disabledButton, setDisabledButton] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const { id } = useParams();

  const columns = [
    { id: "cnpj", label: "CNPJ", minWidth: 170 },
    { id: "name", label: "Nome", minWidth: 100 },
    { id: "statusRisk", label: "Status Risco", minWidth: 100 },
    { id: "comment", label: "Comentario", minWidth: 100 },
    { id: "user", label: "Responsavel", minWidth: 100 },
    { id: "Data", label: "Data", minWidth: 100 },
  ];

  const { state } = useLocation();
  const { getToken } = useAuthMethod();
  if (state?.fromComercial !== true) {
    columns.push({ id: "Ações", label: "Ações", minWidth: 100 });
  }

  const getAnalysis = async () => {
    const token = await getToken();
    try {
      let response = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );
      if (
        response?.statusCompliance?.toUpperCase() === "APROVADO" ||
        response?.statusCompliance?.toUpperCase() === "REPROVADO" ||
        response?.statusCompliance?.toUpperCase() === "REJEITADO"
      ) {
        setCompleted(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("Erro ao salvar");
    } finally {
    }
  };
  useEffect(() => {
    getAnalysis();
  }, []);

  const save = async (status) => {
    const token = await getToken();
    try {
      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          statusCompliance: status,
        }
      );

      toast.success("Salvo com sucesso!");
      setDisabledButton(true);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao salvar");
    } finally {
    }
  };
  console.log(completed, "completed");

  const handleClickApproved = () => {
    save("Aprovado");
  };

  const handleClickRejected = () => {
    save("Reprovado");
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            mb: { xs: 3, xl: 4 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Compliance de documentos
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <DocumentsUpload
          complianceOnly={true}
          fromComercial={state?.fromComercial}
        />
      </Card>
      <div
        className="approveOrRepprove"
        // sx={{ justifyContent: "end", display: "flex", mt: 10 }}
      >
        {state?.fromComercial !== true && !completed && (
          <>
            <Button
              className="complianceButton"
              onClick={handleClickApproved}
              variant="contained"
              component="label"
              disabled={disabledButton}
            >
              Aprovar Proposta
            </Button>
            <Button
              className="complianceButton"
              onClick={handleClickRejected}
              variant="contained"
              component="label"
              disabled={disabledButton}
            >
              Rejeitar Proposta
            </Button>
          </>
        )}
      </div>
    </Box>
  );
};

export default Compliance;
