import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AppBar from "@mui/material/AppBar";
import { Modal, TextareaAutosize, Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate, useParams } from "react-router-dom";
// import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import NeowayReport from "./NeowayReport";
import BigDataReport from "../../../../../elements/BigDataReport";
import Report from "../Report";
import Guarantee from "../Guarantee";
import Form from "../../Proposals/Form";
import DocumentsUpload from "./DocumentsUpload";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import "./style.css";
import Forecast from "../../Proposals/Forecast/ForecastMain";

const AnalysisDetail = () => {
  sessionStorage.getItem("lastname");

  const [open, setOpen] = React.useState(false);
  const [openDocument, setOpenDocument] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [statusDocument, setStatusDocument] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [commentDocument, setCommentDocument] = React.useState("");
  const [value, setValue] = React.useState("1");
  const { user } = useAuthUser();
  const [loading, setLoading] = useState(false);
  const [statusUpload, setStatusUpload] = useState("");
  const [statusForm, setStatusForm] = useState("");
  const [statusForecast, setStatusForecast] = useState("");
  const [proposal, setProposal] = useState({ title: null, description: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [incompleteParts, setIncompleteParts] = useState([]);
  const [finalized, setFinalized] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const { getToken } = useAuthMethod();

  const handleApprove = () => {
    const incomplete = [];
    if (!statusForm) incomplete.push("Formulário");
    if (!statusForecast) incomplete.push("Forecast");
    if (!statusUpload) incomplete.push("Upload");

    if (incomplete.length > 0) {
      setIncompleteParts(incomplete);
      setIsModalOpen(true);
    } else {
      handleClickApproved();
      setIsModalOpen(false);
    }
  };

  const handleClickApproved = () => {
    setStatus("Aprovado");
    setOpen(true);
    setIsModalOpen(false);
  };

  const handleClickRejected = () => {
    setStatus("Rejeitado");
    setOpen(true);
  };

  const handleOnChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleClickSave = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          user: user?.displayName,
          comment: comment,
          statusRisk: status,
        }
      );
      toast.success("Salvo com sucesso!");
      setOpen(false);
      setFinalized(true);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao salvar");
    } finally {
      setLoading(false);
    }
  };

  const setFinalize = async (page) => {
    const token = await getToken();
    try {
      setLoading(true);
      const response = await request(
        process.env.REACT_APP_API_URL + `/${page}/finalize/${id}`,
        "put",
        token
      );
      if (page === "upload") {
        if (response?.status) {
          setStatusUpload(response.status);
        }
      } else if (page === "form") {
        if (response?.status) {
          setStatusForm(response.status);
        }
      } else if (page === "forecast") {
        if (response?.status) {
          setStatusForecast(response.status);
        }
      }

      toast.success("Etapa finalizada com sucesso!");
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao finalizar etapa");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  // vai buscar na api a lista das propopstas (TO DO pode passar algum filtro)
  const getProposal = async function () {
    const token = await getToken();

    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );

      setProposal(result);
      setStatusUpload(result.statusUpload);
      setStatusForm(result.statusForm);
      setStatusForecast(result.statusForecastCompleted);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProposal();
  }, [id]);

  useEffect(() => {
    getProposal();
  }, [statusForm, statusUpload, statusForecast]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box
        className="box"
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
        }}
      >
        <Card
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box sx={{ width: "100%", typography: "body1" }}>
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Relatorio Risco
                </Typography>
              </Toolbar>
            </AppBar>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Neoway" value="1" />
                  <Tab label="BigData" value="2" />
                  <Tab label="Relatorio" value="3" />
                  <Tab label="Formulario" value="4" />
                  <Tab label="Forecast" value="5" />
                  {proposal?.clientType != "VAR" && (
                    <Tab label="Garantia" value="6" />
                  )}

                  <Tab label="Uploads" value="7" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <NeowayReport report={proposal?.neowayData?.neowayData} />
              </TabPanel>
              <TabPanel value="2">
                <BigDataReport report={proposal?.bigData?.bigData} />
              </TabPanel>
              <TabPanel value="3">
                <Report id={proposal?.id} />
              </TabPanel>
              <TabPanel value="4">
                <Form />
              </TabPanel>
              <TabPanel value="5">
                <Forecast />
              </TabPanel>
              <TabPanel value="6">
                <Guarantee guarantee={proposal?.guarantee} />
              </TabPanel>
              <TabPanel value="7">
                <DocumentsUpload />
              </TabPanel>
            </TabContext>
          </Box>

          <div className="footerButtons">
            {(value === "4" || value === "5" || value === "7") && (
              <Button
                className="finalizeButton"
                variant="contained"
                size="medium"
                onClick={() =>
                  value === "4"
                    ? setFinalize("form")
                    : value === "7"
                      ? setFinalize("upload")
                      : value === "5"
                        ? setFinalize("forecast")
                        : null
                }
                disabled={
                  (value === "4" && statusForm === "COMPLETO") ||
                  (value === "7" && statusUpload === "COMPLETO") ||
                  (value === "5" && statusForecast === "COMPLETO")
                }
              >
                {(value === "4" && statusForm === "COMPLETO") ||
                (value === "7" && statusUpload === "COMPLETO") ||
                (value === "5" && statusForecast === "COMPLETO")
                  ? "Etapa finalizada"
                  : "Finalizar Etapa"}
              </Button>
            )}
            <Button
              className="backButton"
              variant="text"
              size="medium"
              onClick={handleBack}
            >
              Voltar
            </Button>
          </div>
        </Card>
        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "1.5rem",
          }}
        >
          <div className="approveOrRepprove">
            {(proposal?.statusRisk?.toUpperCase() === "EM ANALISE" ||
              proposal?.statusRisk?.toUpperCase() === "AJUSTAR GARANTIA") && (
              <>
                <Button
                  disabled={finalized}
                  onClick={handleApprove}
                  variant="contained"
                  component="label"
                  sx={{
                    padding: "9px 12px",
                    // lineHeight: 1,
                    fontWeight: Fonts.MEDIUM,
                    backgroundColor: "#000536",
                    borderRadius: "10px",
                  }}
                >
                  Aprovar Proposta
                </Button>
                <Button
                  disabled={finalized}
                  onClick={handleClickRejected}
                  variant="contained"
                  component="label"
                  sx={{
                    padding: "9px 12px",
                    // lineHeight: 1,
                    fontWeight: Fonts.MEDIUM,
                    backgroundColor: "#000536",
                    borderRadius: "10px",
                  }}
                >
                  Rejeitar Proposta
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Confirmacao</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth size="small">
                      <TextField
                        size="small"
                        id="min"
                        label="Responsavel"
                        variant="outlined"
                        sx={{ mb: 4, mt: 3 }}
                        value={user?.displayName}
                        disabled={true}
                      />
                    </FormControl>

                    <TextareaAutosize
                      onChange={handleOnChangeComment}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder=" Descreva o motivo para tal acao."
                      style={{ width: "100%" }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClickSave}>Salvar</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </div>
        </Box>
        <div>
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div className="modalApprove">
              <Typography variant="h6">
                As seguintes etapas ainda não foram finalizadas:
              </Typography>

              {incompleteParts.map((part, index) => (
                <li style={{ marginTop: "5px" }} key={index}>
                  {part}
                </li>
              ))}

              <Typography style={{ marginTop: "10px" }}>
                Deseja continuar mesmo assim?
              </Typography>
              <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
                <Button
                  className="finalizeButton"
                  variant="contained"
                  color="primary"
                  onClick={handleClickApproved}
                >
                  Sim
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <Hover loading={loading} />
      </Box>
    </>
  );
};

export default AnalysisDetail;
