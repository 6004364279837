/**
 *
 *  Páginas, estrutura do menu e rotas
 *
 * */

import React from "react";
import { Blank } from "elements/blank";
import { AboutPage } from "pages/apps/about";

// Ícones
import {
  AiOutlineBarcode,
  AiOutlineBank,
  AiFillReconciliation,
  AiFillMail,
  AiOutlineSetting,
  AiOutlineInfoCircle,
  AiOutlineDollarCircle,
} from "react-icons/ai";

/**
 * CORBAN BOLETOS
 */
import { ConsultaTransacoes } from "pages/apps/boletos/Transacoes/Transacoes";

/**
 * BANCO LIQUIDANTE E DEPOSITÁRIO
 */
import { Liquidante } from "pages/apps/btg/liquidante/Liquidante";
import { Cessionarios } from "pages/apps/btg/depositario/Cessionarios/Cessionarios";
import { Emissor } from "pages/apps/btg/depositario/Emissor/Emissor";
import { Saldo } from "pages/apps/btg/depositario/Saldo/Saldo";
import { RegistroContratoCessao } from "pages/apps/btg/depositario/ContratoCessao/Registro";
import { ConsultaContratoCessao } from "pages/apps/btg/depositario/ContratoCessao/Consulta";
import { RegistroLimite } from "pages/apps/btg/depositario/ContratoCessao/RegistroLimite";
import { ConsultaLimite } from "pages/apps/btg/depositario/ContratoCessao/ConsultaLimite";
import { ConsultaPlanilha } from "pages/apps/btg/depositario/ContratoCessao/ConsultaPlanilha.jsx";
import { ConsultaConfirmacaoAgenda } from "pages/apps/btg/depositario/ConfirmacaoAgenda/consultaConfirmacaoAgenda.jsx";
import {
  ConsultaRelatorioRecebiveis,
  consultaRelatorioRecebiveis,
} from "pages/apps/btg/depositario/Saldo/ConsultaRelatorioRecebiveis.jsx";
import { FinancialTransaction } from "pages/apps/btg/liquidante/financialTransaction/FinancialTransaction.jsx";
import { Ted } from "pages/apps/btg/depositario/ContratoCessao/Ted.jsx";
import { ConsultarTed } from "pages/apps/btg/depositario/ContratoCessao/ConsultaTed.jsx";

import { Status } from "pages/apps/btg/depositario/ContratoCessao/Status";
import { BtgWebhooksHealthCheck } from "pages/apps/btg/healthCheck";

// Acquirer
import Registration from "./apps/accreditation/acquirerGestation/Registration.jsx";
import List from "./apps/accreditation/acquirerGestation/List.jsx";

// Product
import ProductRegistration from "./apps/accreditation/productGestation/Registration.jsx";
import ProductList from "./apps/accreditation/productGestation/List.jsx";

// Capture Solution
import CaptureRegistration from "./apps/accreditation/captureSolutionGestation/Registration.jsx";
import CaptureList from "./apps/accreditation/captureSolutionGestation/List.jsx";

// Sales Pipeline
import SalesPipelineRegistration from "./apps/accreditation/salesPipelineGestation/Registration.jsx";
import SalesPipelineList from "./apps/accreditation/salesPipelineGestation/List.jsx";

/**
 * ONBOARDING
 */
// propostas
import ProposalForecast from "pages/apps/onboarding/Proposals/Forecast/children/ForecastUploadPreview.jsx";
import CurrentProposal from "./apps/onboarding/Proposals/CurrentProposal";
import ProposalForm from "pages/apps/onboarding/Proposals/Form";
import ComercialForm from "pages/apps/onboarding/Proposals/ComercialForm";
import ListProposal from "pages/apps/onboarding/Proposals/ListProposal";
import ComercialSummary from "pages/apps/onboarding/Proposals/ComercialSummary";
import ListStatus from "pages/apps/onboarding/Proposals/ListStatus";
import ProposalRegistration from "pages/apps/onboarding/Proposals/Registration";
// import ProposalSearch from 'pages/apps/onboarding/Proposals/Search';
import ProposalStatusDetail from "pages/apps/onboarding/Proposals/StatusDetail";

// Jurídico
import DownloadPage from "pages/apps/onboarding/Legal/Contracts/DownloadPage";

// Pricing
import { PricingAnalysisList } from "pages/apps/onboarding/Pricing/Analysis/list";
import PricingCustom from "pages/apps/onboarding/Pricing/Analysis/Proposal";
import StandardPricing from "pages/apps/onboarding/Pricing/Analysis/StandardPrincing";
import StandardMdr from "pages/apps/onboarding/Pricing/Analysis/StandardMdr";
import PricingConditionsList from "pages/apps/onboarding/Pricing/Conditions/FeePlusPriceList";
import PricingConditionUpload from "pages/apps/onboarding/Pricing/Update/index";
import PricingSetCondition from "pages/apps/onboarding/Pricing/SetConditions";
import PricingConditionsPrepaymentList from "pages/apps/onboarding/Pricing/Conditions/PrepaymentFee";
import PricingConditionsMdrList from "pages/apps/onboarding/Pricing/Conditions/FeeMdr";

import SpecialCondition from "pages/apps/onboarding/Pricing/Conditions/SpecialConditionUploads";

// Risco Onboarding
import RiskAnalysisDetail from "pages/apps/onboarding/Risk/Analysis/AnalysisDetail";
import Compliance from "pages/apps/onboarding/Risk/Analysis/Compliance";
import RiskAnalysisList from "pages/apps/onboarding/Risk/Analysis/List";
import RiskBlacklistCnpj from "pages/apps/onboarding/Risk/Blacklist/Cnpj";
import ListQuestions from "pages/apps/onboarding/Risk/ListQuestions";
import CreateQuestions from "pages/apps/onboarding/Risk/CreateQuestions";
//import RiskBlacklistMcc from 'pages/apps/onboarding/Risk/Blacklist/Mcc';
import RiskUpdateIndex from "pages/apps/onboarding/Risk/Update";
import RiskCreateRules from "pages/apps/onboarding/Risk/CreateRules";
import RiskPreCreateRules from "pages/apps/onboarding/Risk/PreCreateRules";
import RiskMccCnae from "pages/apps/onboarding/Risk/MccCnaeList";
import RiskGuarantee from "pages/apps/onboarding/Risk/Guarantee";
import RiskReport from "pages/apps/onboarding/Risk/Report";
import UploadsDocuments from "pages/apps/onboarding/Risk/Analysis/DocumentsUpload";
import ListStatusDetail from "pages/apps/onboarding/Proposals/ListStatusDatail.jsx";

// Risco Credeniamento

import RiskAnalysisDetailCreden from "pages/apps/accreditation/risk/Analysis/AnalysisDetail";
import RiskAnalysisListCreden from "pages/apps/accreditation/risk/Analysis/List";
import RiskCreateRulesCreden from "pages/apps/accreditation/risk/CreateRules.jsx";
import RiskPreCreateRulesCreden from "pages/apps/accreditation/risk/PreCreateRules";
import RiskBlacklistCnpjCreden from "pages/apps/accreditation/risk/Blacklist/Cnpj";
import RiskMccCnaeCreden from "pages/apps/accreditation/risk/MccCnaeList";
import RiskUpdateIndexCreden from "pages/apps/accreditation/risk/Update";
import ComplianceCreden from "pages/apps/accreditation/risk/Analysis/Compliance";

import RiskGuaranteeCreden from "pages/apps/accreditation/risk/Guarantee";
import RiskReportCreden from "pages/apps/accreditation/risk/Report";
import UploadsDocumentsCreden from "pages/apps/accreditation/risk/Analysis/DocumentsUpload";

/**
 *  CARTAS (META)
 */
import { ConsultaExtratoCartasClient } from "pages/apps/cartas/Consulta_ExtratoCartasClient";
import { ConsultaEntregaEmailsClient } from "pages/apps/cartas/Consulta_EntregaEmailsClient";

/**
 * ADMIN
 */
import { Grupos } from "pages/apps/admin/grupos";

// Taxas

import { Taxas } from "pages/apps/taxas/Taxas";

// BCodex

import BCodex from "pages/apps/bcodex/Consult";
import Consulta from "pages/apps/bcodex/Consult";

// Escrow

import { ConsultaAccountId } from "pages/apps/btg/escrow/consultaAccountId.jsx";
import { ConsultaSaldo } from "pages/apps/btg/escrow/consultaSaldo.jsx";
import { ConsultaSaldoFinalDia } from "pages/apps/btg/escrow/consultaSaldoFinalDia.jsx";
import { GerarExtrato } from "pages/apps/btg/escrow/gerarExtrato.jsx";
import { ConsultaExtrato } from "pages/apps/btg/escrow/consultaExtrato.jsx";
import { EmpilhamentoCessao } from "pages/apps/btg/escrow/empilhamentoCessao.jsx";
import { ConsultaStatusExtrato } from "pages/apps/btg/escrow/consultaStatusExtrato.jsx";
import PortfolioManagement from "./apps/accreditation/portfolioManagement";
import CategoryManagement from "./apps/accreditation/CategoryManagement";
import ForecastMain from "./apps/onboarding/Proposals/Forecast/ForecastMain.jsx";

///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////

/**
 * PÁGINAS E ROTAS
 */
export const pages = [
  {
    title: "Onboarding",
    path: "onboarding",
    icon: <AiFillReconciliation />,
    element: <Blank />,
    allowedGroups: ["DEV"],
    children: [
      {
        title: "Propostas",
        path: "proposals",
        icon: null,
        element: <Blank />,
        allowedGroups: [],
        children: [
          {
            title: "Compliance",
            path: "compliance/:id",
            icon: null,
            noMenu: true,
            element: <Compliance />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Consulta",
            path: "currentProposal/:id",
            icon: null,
            noMenu: true,
            element: <CurrentProposal />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Cadastrar",
            path: "form/:id",
            icon: null,
            noMenu: true,
            element: <ProposalForm />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Status de Proposta",
            path: "detail/:id",
            noMenu: true,
            icon: null,
            element: <ProposalStatusDetail />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Registrar",
            path: "registration",
            icon: null,
            element: <ProposalRegistration />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Listar Status",
            path: "liststatus",
            icon: null,
            element: <ListStatus />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Listar Status",
            path: "liststatus/detail/:id",
            noMenu: true,
            icon: null,
            element: <ListStatusDetail />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Listar Propostas",
            path: "listProposals",
            icon: null,
            element: <ListProposal />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Forecast",
            path: "forecast/:id",
            noMenu: true,
            icon: null,
            element: <ForecastMain />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },

          {
            title: "Relatorio",
            path: "report/:proposalId",
            noMenu: true,
            icon: null,
            element: <RiskReport />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Uploads",
            path: "uploads/:id",
            noMenu: true,
            icon: null,
            element: <UploadsDocuments />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "ComercialForm",
            path: "comercialform/:id",
            noMenu: true,
            icon: null,
            element: <ComercialForm />,
            allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Summary Comercial",
            path: "summary/:id",
            noMenu: true,
            icon: null,
            element: <ComercialSummary />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
        ],
      },
      {
        title: "Risco",
        path: "risk",
        icon: null,
        element: <Blank />,
        allowedGroups: ["DEV"],
        children: [
          {
            title: "Análise",
            path: "analysis",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [
              {
                title: "Detalhe",
                path: "detail/:id",
                noMenu: true,
                icon: null,
                element: <RiskAnalysisDetail />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "Listar",
                path: "analysislist",
                icon: null,
                element: <RiskAnalysisList />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur", "AD_Risco"],
                children: [],
              },
            ],
          },
          {
            title: "Regras",
            path: "rules",
            icon: null,
            element: <Blank />,
            allowedGroups: [],
            children: [
              {
                title: "Criar",
                path: "create/:id",
                noMenu: true,
                icon: null,
                element: <RiskCreateRules />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "Criar",
                path: "precreate",
                icon: null,
                element: <RiskPreCreateRules />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
            ],
          },
          {
            title: "Perguntas",
            path: "questions",
            icon: null,
            // noMenu: true,
            element: <Blank />,
            allowedGroups: [],
            children: [
              {
                title: "Criar",
                path: "createquestions",
                icon: null,
                element: <CreateQuestions />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "Listar",
                path: "listquestions",
                icon: null,
                element: <ListQuestions />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
            ],
          },
          {
            title: "Blacklists",
            path: "blacklist",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [
              {
                title: "CNPJs",
                path: "cnpj",
                icon: null,
                element: <RiskBlacklistCnpj />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              // {
              //   title: 'MCCs',
              //   path: 'mcc',
              //   icon: null,
              //   element: <RiskBlacklistMcc />,
              //   allowedGroups: ['DEV', 'RISCO', 'PRODUTOS'],
              //   children: [],
              // },
            ],
          },
          {
            title: "MCC/CNAE",
            path: "mcccnae",
            icon: null,
            element: <RiskMccCnae />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },

          {
            title: "Atualizar Lista MCCs",
            path: "update",
            icon: null,
            element: <RiskUpdateIndex />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Compliance",
            path: "compliance/:id",
            icon: null,
            noMenu: true,
            element: <Compliance />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
        ],
      },
      {
        title: "Precificacao",
        path: "pricing",
        icon: null,
        element: <Blank />,
        allowedGroups: [],
        children: [
          {
            title: "Análises",
            path: "analysis",
            icon: null,
            element: <Blank />,
            allowedGroups: [],
            children: [
              {
                title: "Listar",
                path: "list",
                icon: null,
                element: <PricingAnalysisList />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "custom",
                path: "customproposal/:id",
                icon: null,
                noMenu: true,
                element: <PricingCustom />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "standard",
                path: "standardproposal/:id",
                icon: null,
                noMenu: true,
                element: <StandardPricing />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "standardmdr",
                path: "standardmdr/:id",
                icon: null,
                noMenu: true,
                element: <StandardMdr />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "special",
                path: "special/:id",
                icon: null,
                noMenu: true,
                element: <SpecialCondition />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
            ],
          },
          {
            title: "Condições",
            path: "conditions",
            icon: null,
            element: <Blank />,
            allowedGroups: [],
            children: [
              {
                title: "Condições",
                path: "feepluspricelist",
                icon: null,
                element: <PricingConditionsList />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "Condições Prepayment",
                path: "prepaymentfee",
                icon: null,
                element: <PricingConditionsPrepaymentList />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
              {
                title: "Condições por Bandeiras e MCCs",
                path: "feemdr",
                icon: null,
                element: <PricingConditionsMdrList />,
                allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
                children: [],
              },
            ],
          },

          {
            title: "Configurar parâmetros ",
            path: "setcondition",
            icon: null,
            element: <PricingSetCondition />,
            allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
          {
            title: "Atualizar condições padrões",
            path: "/update",
            icon: null,
            element: <PricingConditionUpload />,
            allowedGroups: ["DEV", "PRICING", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
        ],
      },
      {
        title: "Jurídico",
        path: "legal",
        icon: null,
        element: <Blank />,
        allowedGroups: [],
        children: [
          {
            title: "Baixar dados contratuais",
            path: "downloadpage",
            icon: null,
            element: <DownloadPage />,
            allowedGroups: ["DEV", "JURIDICO", "PRODUTOS", "AD_Onb_Jur"],
            children: [],
          },
        ],
      },
    ],
  },
  {
    title: "Credenciamento",
    path: "accreditation",
    icon: <AiFillReconciliation />,
    element: <Blank />,
    allowedGroups: [],
    children: [
      {
        title: "Parâmetros",
        path: "parameters",
        icon: null,
        element: <Blank />,
        allowedGroups: ["DEV"],
        children: [
          {
            title: "Gestão da Adquirente",
            path: "acquirerGestation",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV"],
            children: [
              {
                title: "Cadastro",
                path: "registration",
                icon: null,
                noMenu: true,
                children: [],
                element: <Registration />,
                allowedGroups: ["DEV"],
              },
              {
                title: "Consulta",
                path: "List",
                icon: null,
                children: [],
                element: <List />,
                allowedGroups: ["DEV"],
              },
            ],
          },
          {
            title: "Gestão de Produto",
            path: "productGestation",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV"],
            children: [
              {
                title: "Cadastro",
                path: "registration",
                icon: null,
                noMenu: true,
                children: [],
                element: <ProductRegistration />,
                allowedGroups: ["DEV"],
              },
              {
                title: "Consulta",
                path: "List",
                icon: null,
                children: [],
                element: <ProductList />,
                allowedGroups: ["DEV"],
              },
            ],
          },
          {
            title: "Gestão Solução de Captura",
            path: "captureSolutionGestation",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV"],
            children: [
              {
                title: "Cadastro",
                path: "registration",
                icon: null,
                noMenu: true,
                children: [],
                element: <CaptureRegistration />,
                allowedGroups: ["DEV"],
              },
              {
                title: "Consulta",
                path: "List",
                icon: null,
                children: [],
                element: <CaptureList />,
                allowedGroups: ["DEV"],
              },
            ],
          },
          {
            title: "Gestão Canal de Vendas",
            path: "salespipelineGestation",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV"],
            children: [
              {
                title: "Cadastro",
                path: "registration",
                icon: null,
                noMenu: true,
                children: [],
                element: <SalesPipelineRegistration />,
                allowedGroups: ["DEV"],
              },
              {
                title: "Consulta",
                path: "List",
                icon: null,
                children: [],
                element: <SalesPipelineList />,
                allowedGroups: ["DEV"],
              },
            ],
          },
        ],
      },
      {
        title: "Risco",
        path: "risk",
        icon: null,
        element: <Blank />,
        allowedGroups: ["DEV"],
        children: [
          {
            title: "Análise",
            path: "analysis",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS"],
            children: [
              {
                title: "Detalhe",
                path: "detail/:id",
                noMenu: true,
                icon: null,
                element: <RiskAnalysisDetailCreden />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS"],
                children: [],
              },
              {
                title: "Listar",
                path: "analysislist",
                icon: null,
                element: <RiskAnalysisListCreden />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS", "AD_Risco"],
                children: [],
              },
            ],
          },
          {
            title: "Regras",
            path: "rules",
            icon: null,
            element: <Blank />,
            allowedGroups: [],
            children: [
              {
                title: "Criar",
                path: "create/:id",
                noMenu: true,
                icon: null,
                element: <RiskCreateRulesCreden />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS"],
                children: [],
              },
              {
                title: "Criar",
                path: "precreate",
                icon: null,
                element: <RiskPreCreateRulesCreden />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS"],
                children: [],
              },
            ],
          },
          {
            title: "Blacklists",
            path: "blacklist",
            icon: null,
            element: <Blank />,
            allowedGroups: ["DEV", "RISCO", "PRODUTOS"],
            children: [
              {
                title: "CNPJs",
                path: "cnpj",
                icon: null,
                element: <RiskBlacklistCnpjCreden />,
                allowedGroups: ["DEV", "RISCO", "PRODUTOS"],
                children: [],
              },
              // {
              //   title: 'MCCs',
              //   path: 'mcc',
              //   icon: null,
              //   element: <RiskBlacklistMcc />,
              //   allowedGroups: ['DEV', 'RISCO', 'PRODUTOS'],
              //   children: [],
              // },
            ],
          },
          //{
          //  title: 'MCC/CNAE',
          //  path: 'mcccnae',
          //  icon: null,
          //  element: <RiskMccCnaeCreden />,
          //  allowedGroups: ['DEV', 'RISCO', 'PRODUTOS'],
          //  children: [],
          //},

          //{
          //  title: 'Atualizar Lista MCCs',
          //  path: 'update',
          //  icon: null,
          //  element: <RiskUpdateIndexCreden />,
          //  allowedGroups: ['DEV', 'RISCO', 'PRODUTOS'],
          //  children: [],
          //},
          //{
          //  title: 'Compliance',
          //  path: 'compliance/:id',
          //  icon: null,
          //  noMenu: true,
          //  element: <ComplianceCreden />,
          //  allowedGroups: ['DEV', 'RISCO', 'PRODUTOS'],
          //  children: [],
          //},
        ],
      },
      {
        title: "Gestão de Carteira",
        path: "portfolio_management",
        icon: null,
        element: <PortfolioManagement />,
        allowedGroups: ["DEV", "COMERCIAL", "PRODUTOS", "AD_Onb_Jur"],
        children: [],
      },
      {
        title: "Gestão de Filas",
        path: "category_management",
        icon: null,
        element: <CategoryManagement />,
        allowedGroups: ["DEV", "COMERCIAL", "RISCO", "PRODUTOS", "AD_Onb_Jur"],
        children: [],
      },
    ],
  },
  // {
  //   title: 'fase2',
  //   path: 'fase2',
  //   icon: <AiOutlineBarcode />,
  //   element: <Blank />,
  //   allowedGroups: [],
  //   children: [
  //     {
  //       title: 'setPerfil',
  //       path: 'setPerfil',
  //       icon: null,
  //       children: [],
  //       element: <SetPerfil />,
  //       allowedGroups: ['DEV'],
  //     },  {
  //       title: 'EditPerfil',
  //       path: 'EditPerfil',
  //       icon: null,
  //       children: [],
  //       element: <EditPerfil />,
  //       allowedGroups: ['DEV'],
  //     },
  //   ],
  // },
  {
    title: "Boletos",
    path: "boletos",
    icon: <AiOutlineBarcode />,
    element: <Blank />,
    allowedGroups: [],
    children: [
      {
        title: "Consultas",
        path: "consultas",
        icon: null,
        children: [],
        element: <ConsultaTransacoes />,
        allowedGroups: ["DEV", "OPERACOES", "CONTABILIDADE"],
      },
    ],
  },
  {
    title: "Cartas e extratos",
    path: "cartas",
    icon: <AiFillMail />,
    element: <Blank />,
    allowedGroups: [],
    children: [
      {
        title: "Consulta",
        path: "Consulta",
        icon: null,
        children: [],
        element: <ConsultaExtratoCartasClient />,
        allowedGroups: ["DEV", "OPERACOES"],
      },
      {
        title: "Estatísticas de envio",
        path: "estatisticas",
        icon: null,
        children: [],
        element: <ConsultaEntregaEmailsClient />,
        allowedGroups: ["DEV", "OPERACOES"],
      },
    ],
  },
  {
    title: "BTG",
    path: "btg",
    icon: <AiOutlineBank />,
    element: <Blank />,
    allowedGroups: [],
    children: [
      {
        title: "Banco Liquidante",
        path: "liquidante",
        icon: null,
        element: <Blank />,
        allowedGroups: [],
        children: [
          {
            title: "Transações",
            path: "transacoes",
            icon: null,
            children: [],
            element: <Liquidante />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Transaçoes Financeiras",
            path: "financialTransaction",
            icon: null,
            children: [],
            element: <FinancialTransaction />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
        ],
      },
      {
        title: "Banco Depositário",
        path: "depositario",
        icon: null,
        element: <Blank />,
        allowedGroups: [],
        children: [
          {
            title: "Consulta Cessionários",
            path: "cessionarios",
            icon: null,
            children: [],
            element: <Cessionarios />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Consulta Emissores",
            path: "emissores",
            icon: null,
            children: [],
            element: <Emissor />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Consulta Saldo",
            path: "saldo",
            icon: null,
            children: [],
            element: <Saldo />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Confirmação da agenda",
            path: "consultarConfirmacaoAgenda",
            icon: null,
            children: [],
            element: <ConsultaConfirmacaoAgenda />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          /* {
                      title: 'Consultar Relatorio Recebiveis',
                      path: 'consultarRelatorioRecebiveis',
                      icon: null,
                      children: [],
                      element: <ConsultaRelatorioRecebiveis />,
                      allowedGroups: ['DEV', 'TESOURARIA'],
                    }, */
          {
            title: "Contratos de Cessão",
            path: "contratoscessao",
            icon: null,
            element: <Blank />,
            allowedGroups: [],
            children: [
              /* {
                              title: 'Status',
                              path: 'status',
                              icon: null,
                              children: [],
                              element: <Status />,
                              allowedGroups: ['DEV', 'TESOURARIA'],
                            }, */
              {
                title: "Registrar",
                path: "registrar",
                icon: null,
                children: [],
                element: <RegistroContratoCessao />,
                allowedGroups: ["DEV", "TESOURARIA"],
              },
              {
                title: "Consultar",
                path: "consultar",
                icon: null,
                children: [],
                element: <ConsultaContratoCessao />,
                allowedGroups: ["DEV", "TESOURARIA"],
              },
              {
                title: "Registrar Limite",
                path: "registrarlimite",
                icon: null,
                children: [],
                element: <RegistroLimite />,
                allowedGroups: ["DEV", "TESOURARIA"],
              },
              {
                title: "Consultar Limite",
                path: "consultarLimite",
                icon: null,
                children: [],
                element: <ConsultaLimite />,
                allowedGroups: ["DEV", "TESOURARIA"],
              },
              {
                title: "Consultar Planilha",
                path: "consultarPlanilha",
                icon: null,
                children: [],
                element: <ConsultaPlanilha />,
                allowedGroups: ["DEV", "TESOURARIA"],
              },
              {
                title: "Ted",
                path: "ted",
                icon: null,
                children: [],
                element: <Ted />,
                /* allowedGroups: ['DEV', 'TESOURARIA'], */
                allowedGroups: ["NOBODY"],
              },
              {
                title: "Consultar Teds",
                path: "consultarTeds",
                icon: null,
                children: [],
                element: <ConsultarTed />,
                /* allowedGroups: ['DEV', 'TESOURARIA'], */
                allowedGroups: ["NOBODY"],
              },
            ],
          },
        ],
      },
      {
        title: "Escrow",
        path: "escrow",
        icon: null,
        element: <Blank />,
        allowedGroups: [],
        children: [
          {
            title: "Consultar Account Id",
            path: "consultarAccountId",
            icon: null,
            children: [],
            element: <ConsultaAccountId />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Consultar Saldo",
            path: "consultarSaldo",
            icon: null,
            children: [],
            element: <ConsultaSaldo />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Consultar Saldo Final Do Dia",
            path: "consultarSaldoFinalDoDia",
            icon: null,
            children: [],
            element: <ConsultaSaldoFinalDia />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          /* {
                      title: 'Gerar Extrato',
                      path: 'gerarExtrato',
                      icon: null,
                      children: [],
                      element: <GerarExtrato />,
                      allowedGroups: ['DEV', 'TESOURARIA'],
                    }, */
          {
            title: "Consultar Extrato",
            path: "consultarExtrato",
            icon: null,
            children: [],
            element: <ConsultaExtrato />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          {
            title: "Empilhamento de cessão",
            path: "empilhamentoCessao",
            icon: null,
            children: [],
            element: <EmpilhamentoCessao />,
            allowedGroups: ["DEV", "TESOURARIA"],
          },
          /* {
                      title: 'Consultar Pedido de Extrato',
                      path: 'consultarStatusExtrato',
                      icon: null,
                      children: [],
                      element: <ConsultaStatusExtrato />,
                      allowedGroups: ['DEV', 'TESOURARIA'],
                    }, */
        ],
      },
      {
        title: "Health Check",
        path: "healthcheck",
        icon: null,
        children: [],
        element: <BtgWebhooksHealthCheck />,
        allowedGroups: ["DEV"],
      },
    ],
  },
  {
    title: "Admin",
    path: "admin",
    icon: <AiOutlineSetting />,
    element: <Blank />,
    allowedGroups: ["DEV"],
    children: [
      {
        title: "Grupos",
        path: "grupos",
        icon: null,
        children: [],
        element: <Grupos />,
        allowedGroups: ["DEV"],
      },
      {
        title: "About",
        path: "about",
        icon: null,
        children: [],
        element: <AboutPage />,
        allowedGroups: ["ALL"],
      },
    ],
  },
  {
    title: "Taxas",
    path: "taxas",
    icon: <AiOutlineDollarCircle />,
    element: <Taxas />,
    allowedGroups: ["ALL"],
    children: [],
  },
  {
    title: "BCodex",
    path: "bcodex",
    icon: <AiOutlineDollarCircle />,
    element: <Blank />,
    allowedGroups: ["RISCO", "DEV"],
    children: [
      {
        title: "Consulta",
        path: "consulta",
        icon: null,
        element: <Consulta />,
        allowedGroups: ["DEV", "RISCO"],
        children: [],
      },
    ],
  },
];
