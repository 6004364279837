import * as React from "react";
import Grid from "@mui/material/Grid";
import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Fonts } from "shared/constants/AppEnums";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import { Hover } from "elements/hover";
import "./style.css";

function DocumentsUpload(props) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [open, setOpen] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [showSendMail, setShowSendMail] = useState(false);
  const [analysis, setAnalysis] = useState();
  const [specialFile, setSpecialFile] = useState([]);

  const { id } = useParams();
  const { state } = useLocation();
  const { getToken } = useAuthMethod();

  const handleClickUpload = async (uploadId) => {
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + `/upload/url/${uploadId}`
      );
      window.open(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  const listFiles = async function () {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/documentsupload/${id}`,
        "get",
        token
      );
      const analysisResult = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );

      let docs = result;
      if (props.complianceOnly) {
        docs = docs.filter(
          (v) =>
            !["cartaoCnpj", "CPF", "proofResidence"].includes(v.documentType)
        );
      } else {
        const validDocumentTypes = [
          "cartaoCnpj",
          "CPF",
          "proofResidence",
          "tax",
          "RG",
          "proofResidencePartner",
          "contract",
          "financialBalance",
          "proofResidenceCompany",
          "cardCNPJ",
          "proofResidencePartner",
          "varProof",
          "others",
        ];

        docs = docs.filter((v) =>
          validDocumentTypes.some((type) => v.documentType.includes(type))
        );
      }
      setSpecialFile(
        result?.filter(
          (v) => v.documentType == "Pricing" || v.documentType == "Risk"
        )
      );

      setShowSendMail(false);
      for (const doc of docs) {
        if (doc.status.toLowerCase() === "pendente") {
          setShowSendMail(true);
          break;
        }
      }
      setAnalysis(analysisResult);
      setDocuments(docs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listFiles();
  }, [id]);

  const updateStatus = async (id, status) => {
    const token = await getToken();

    setLoading(true);
    try {
      await request(
        process.env.REACT_APP_API_URL + `/documentsupload/${id}`,
        "put",
        token,
        {
          status: status,
        }
      );
      toast.success(" Atualizado com sucesso!");
      listFiles();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao fazer updates");
    } finally {
      setLoading(false);
    }
  };

  const handleClickSendMail = async () => {
    const token = await getToken();

    setLoading(true);
    try {
      await request(
        process.env.REACT_APP_API_URL + `/senddocumentconfirmation`,
        "post",
        token,
        { emailText }
      );
      toast.success("Email enviado com sucesso.");
      handleClose();
      listFiles();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao enviar email");
    } finally {
      setLoading(false);
    }
  };

  const handleClickSavePendente = (id) => {
    updateStatus(id, "Pendente");
  };

  const handleClose = () => {
    setOpen(false);
    setDocumentId(null);
  };

  const handleOnChangeEmailText = (event) => setEmailText(event.target.value);

  return (
    <div className="details">
      <h2 className="title-details">Documentos enviados pelo cliente</h2>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tipo de documento</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Status</TableCell>
              {state?.fromComercial != true && (
                <>
                  <TableCell>Acoes</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.documentType === "proofResidence"
                      ? "Comprovante de endereço"
                      : row.documentType}
                  </TableCell>
                  <TableCell sx={{ wordBreak: "break-all", width: 300 }}>
                    <a href={"#void"} onClick={() => handleClickUpload(row.id)}>
                      {row.fileName}
                    </a>
                  </TableCell>
                  <TableCell>{row.status}</TableCell>

                  {state?.fromComercial != true && (
                    <>
                      <TableCell>
                        <Button
                          sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                          variant="contained"
                          disabled={loading}
                          onClick={() => updateStatus(row.id, "Confirmado")}
                        >
                          Confirmar
                        </Button>{" "}
                        <Button
                          sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                          variant="contained"
                          disabled={loading}
                          onClick={() => handleClickSavePendente(row.id)}
                        >
                          Pendente
                        </Button>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
        {showSendMail && state?.fromComercial != true && (
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            component="label"
            sx={{
              marginRight: 5,
              marginTop: 2,
              padding: "9px 12px",
              lineHeight: 1,
              fontWeight: Fonts.MEDIUM,
              backgroundColor: "navy",
            }}
          >
            Enviar Email
          </Button>
        )}
      </Box>
      <Typography sx={{ padding: 5, fontSize: 16 }}>
        Evidências das Condições Especiais
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Área envolvida</TableCell>
              <TableCell>Descrição da Condição</TableCell>
              <TableCell>Evidência de Aprovação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Pricing
              </TableCell>
              <TableCell>{analysis?.specialDescriptionPricing}</TableCell>
              <TableCell sx={{ wordBreak: "break-all", width: 300 }}>
                {specialFile
                  ?.filter((doc) => doc.documentType === "Pricing")
                  ?.map((row) => {
                    return (
                      <React.Fragment key={row.id}>
                        <a
                          href={"#void"}
                          onClick={() => handleClickUpload(row.id)}
                        >
                          {row.fileName}
                        </a>{" "}
                      </React.Fragment>
                    );
                  })}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Risco
              </TableCell>
              <TableCell>{analysis?.specialDescriptionRisk}</TableCell>
              <TableCell sx={{ wordBreak: "break-all", width: 300 }}>
                {specialFile
                  ?.filter((doc) => doc.documentType === "Risk")
                  ?.map((row) => {
                    return (
                      <React.Fragment key={row.id}>
                        <a
                          href={"#void"}
                          onClick={() => handleClickUpload(row.id)}
                        >
                          {row.fileName}
                        </a>{" "}
                      </React.Fragment>
                    );
                  })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Hover loading={loading} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Marcar como pendente</DialogTitle>
        <DialogContent>
          <TextField
            onChange={handleOnChangeEmailText}
            aria-label="minimum height"
            minRows={3}
            multiline
            placeholder="Informe o cliente em questão e o que não está de acordo em relação aos documentos.Um email será enviado para o time Comercial e será tratado com o cliente."
            style={{ width: "500px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button onClick={handleClickSendMail} disabled={loading}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DocumentsUpload;
DocumentsUpload.defaultProps = {};
DocumentsUpload.propTypes = {
  complianceOnly: PropTypes.boolean,
};
