import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AppBar from "@mui/material/AppBar";
import { TextareaAutosize, Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import NeowayReport from "../Risk/Analysis/NeowayReport";
import BigDataReport from "../../../../elements/BigDataReport";
import Report from "../Risk/Report";
import Guarantee from "../Risk/Guarantee";
import Form from "./Form";
import Forecast from "./Forecast/children/ForecastUploadPreview";
import DocumentsUpload from "../Risk/Analysis/DocumentsUpload";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import ProposalForm from "pages/apps/onboarding/Proposals/Question/ProposalQuestion";

const ListStatusDetail = () => {
  sessionStorage.getItem("lastname");
  const [open, setOpen] = React.useState(false);
  const [openDocument, setOpenDocument] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [statusDocument, setStatusDocument] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [commentDocument, setCommentDocument] = React.useState("");
  const [value, setValue] = React.useState("1");
  const { user } = useAuthUser();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { getToken } = useAuthMethod();

  const [proposal, setProposal] = useState({ title: null, description: null });

  const handleClickApproved = () => {
    setStatus("Aprovado");
    setOpen(true);
  };

  const handleClickRejected = () => {
    setStatus("Rejeitado");
    setOpen(true);
  };

  const handleOnChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleClickRejectedDocument = () => {
    setStatusDocument("Rejeitado");
    setOpenDocument(true);
  };
  const handleClickApprovedDocument = () => {
    setStatusDocument("Aprovado");
    setOpenDocument(true);
  };
  const handleOnChangeCommentDocument = (event) => {
    setCommentDocument(event.target.value);
  };

  const handleClickSave = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          user: user?.displayName,
          comment: comment,
          statusRisk: status,
        }
      );
      toast.success(" Salvo com sucesso!");
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao salvar");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // vai buscar na api a lista das propopstas (TO DO pode passar algum filtro)
  const getProposal = async function () {
    const token = await getToken();

    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );

      setProposal(result);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProposal();
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
      }}
    >
      <Card
        sx={{
          // maxWidth: 1024,
          width: "100%",
          overflow: "hidden",
          // padding: { xs: 8, md: 12 },
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          // paddingLeft: {
          //   lg: 8,
          //   xl: 20,
          // },
          // paddingRight: {
          //   lg: 12,
          //   xl: 20,
          // },
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Relatorio Risco
              </Typography>
            </Toolbar>
          </AppBar>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Uploads" value="1" />
                <Tab label="Questionário" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <DocumentsUpload />
            </TabPanel>
            <TabPanel value="2">
              <ProposalForm report={proposal?.bigData?.bigData} />
            </TabPanel>
          </TabContext>
        </Box>
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
          {(proposal?.statusRisk?.toUpperCase() === "EM ANALISE" ||
            proposal?.statusRisk?.toUpperCase() === "AJUSTAR GARANTIA") &&
            proposal?.statusUpload != null &&
            proposal?.statusForm != null &&
            proposal?.statusForecastCompleted != null && (
              <>
                <Button
                  onClick={handleClickApproved}
                  variant="contained"
                  component="label"
                  sx={{
                    marginRight: 5,
                    marginTop: 2,
                    padding: "9px 12px",
                    lineHeight: 1,
                    width: 96,
                    fontWeight: Fonts.MEDIUM,
                    backgroundColor: "#000536",
                  }}
                >
                  Aprovar
                </Button>
                <Button
                  onClick={handleClickRejected}
                  variant="contained"
                  component="label"
                  sx={{
                    marginRight: 5,
                    marginTop: 2,
                    padding: "9px 12px",
                    lineHeight: 1,
                    width: 96,
                    fontWeight: Fonts.MEDIUM,
                    backgroundColor: "#000536",
                  }}
                >
                  Rejeitar
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Confirmacao</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth size="small">
                      <TextField
                        size="small"
                        id="min"
                        label="Responsavel"
                        variant="outlined"
                        sx={{ mb: 4, mt: 3 }}
                        value={user?.displayName}
                        disabled={true}
                      />
                    </FormControl>

                    <TextareaAutosize
                      onChange={handleOnChangeComment}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder=" Descreva o motivo para tal acao."
                      style={{ width: "100%" }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClickSave}>Salvar</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
        </Box>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default ListStatusDetail;
