import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import "../style.css";
import { useNavigate } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button } from "@mui/material";

const ForecastUploadPreview = ({ loading, file }) => {
  let docs = [
    {
      uri: file,
      fileType: "xlsx",
      fileName: "Forecast",
    },
  ];

  return (
    <>
      <div
        className="documentPreview"
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          style={{ flex: 1 }}
        />
      </div>
    </>
  );
};

export default ForecastUploadPreview;
